import { put, call, takeLatest } from 'redux-saga/effects';
import {
  LOGIN_SUCCESSED,
  LOGIN_FAILED,
  LOGIN_REQUESTED,
  LOGOUT_SUCCESSED,
  LOGOUT_FAILED,
  LOGOUT_REQUESTED,
  SHOW_LOADER,
  HIDE_LOADER,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_SUCCESSED,
  FORGOT_PASSWORD_FAILED,
  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_SUCCESSED,
  RESET_PASSWORD_FAILED,
  CHANGE_PASSWORD_REQUESTED,
  CHANGE_PASSWORD_SUCCESSED,
  CHANGE_PASSWORD_FAILED,
  INVITED_USER_REQUESTED,
  INVITED_USER_SUCCESSED,
  INVITED_USER_FAILED,
  VERIFY_CODE_REQUESTED,
  VERIFY_CODE_SUCCESSED,
  VERIFY_CODE_FAILED,
  GOOGLE_AUTH_LOGIN_REQUESTED,
  GOOGLE_AUTH_LOGIN_SUCCESSED,
  GOOGLE_AUTH_LOGIN_FAILED,
  MASTER_AUTH_LOGIN_REQUESTED,
  MASTER_AUTH_LOGIN_SUCCESSED,
  MASTER_AUTH_LOGIN_FAILED,
  USER_PROFILE_SUCCESSED,
  USER_CONFIRMATION_REQUESTED,
  USER_CONFIRMATION_SUCCESSED,
  USER_CONFIRMATION_FAILED,
} from '../../constants/actionTypes';
import {
  loginSaga,
  forgotPasswordSaga,
  googleLoginSaga,
  masterLoginSaga,
  verifyCodeSaga,
  resetPasswordSaga,
  changePasswordSaga,
  invitedUserSaga,
  userConfirmationSaga
} from '../../api/loginSaga';
import { logoutSaga } from '../../api/logoutSaga';
import {
  userProfileSaga,
} from '../../api/userSaga';
import moment from 'moment';
import { dismissAllToasts, dismissToastLoader, showToastError, showToastLoader, showToastSuccess } from '../../utils/toasts';

export function* watchLoginDetails(action) {
  dismissAllToasts()
  const loaderHandle = showToastLoader('Logging in...')
  try {
    const loginData = yield call(loginSaga, action.payload);

    if (loginData.success) {
      dismissToastLoader(loaderHandle)
      localStorage.clear();
      localStorage.setItem('AccessToken', loginData.data.token);
      localStorage.setItem('isExecute', true);
      localStorage.setItem('AccessToken2FA', loginData.data.token);
      localStorage.setItem('showWidget', true);

      if (loginData?.data?.poc_force_date_range) {
        const custom_date = loginData?.data?.poc_force_date_range?.split("/");
        const startD = moment(custom_date[0]).format('YYYY-MM-DD');
        const endD = moment(custom_date[1]).format('YYYY-MM-DD');
        localStorage.setItem('start', startD);
        localStorage.setItem('end', endD);
      }
      if (loginData?.data?.default_dashboard !== '') {
        localStorage.setItem('defaultDashboard', loginData?.data?.default_dashboard);
      }
      if (loginData?.data?.googleAuth === 'Enable') {
        setTimeout(() => action.history.push('/verify-code'), 0)
      } else if (loginData?.data?.googleAuth === 'Disable' && loginData?.data?.forceEnable2fa == 1) {
        setTimeout(() => action.history.push('/google-authenticate'), 0)
      } else {
        localStorage.removeItem('AccessToken2FA');
        const excludedPaths = ['/', '/login', '/invited-user', '/change-password', '/update-license', '/permissionDenied'];
        if (action?.previousPath && !(excludedPaths?.includes(action?.previousPath))) {
          setTimeout(() => action.history.push(action.previousPath), 0);
        } else {
          setTimeout(() => action.history.push('/' + loginData?.data?.landingPage), 0);
        }
      }

      // Getting user profile to store permissions in local storage for permission based routing
      const userProfileResponse = yield call(userProfileSaga);
      yield put({ type: USER_PROFILE_SUCCESSED, data: userProfileResponse });
      localStorage.setItem('access', JSON.stringify(userProfileResponse?.data?.access));

      yield put({ type: LOGIN_SUCCESSED, data: loginData });
    } else {
      dismissToastLoader(loaderHandle)
      yield put({ type: LOGIN_FAILED, data: null });
    }
  } catch (err) {
    dismissToastLoader(loaderHandle)
    yield put({ type: LOGIN_FAILED, data: err?.response?.data?.data });
    // Redirect to change password page if user's password expires
    if (err?.response?.status == 422 && err?.response?.data?.data?.msg == 'Password expired') {
      showToastError(err?.response?.data?.data?.msg);
      action.history.push(`/change-password/${err?.response?.data?.data?.user_id}`);
      return
    }
    // Redirect to license upload page if license expires
    if (err?.response?.status == 422) {
      localStorage.setItem('organization', err?.response?.data?.data?.organization);
      return
    };
  }
}

export function* watchUserLogout(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const logoutData = yield call(logoutSaga, action.payload);
    if (logoutData.success) {
      localStorage.clear();
      yield put({ type: LOGOUT_SUCCESSED, data: logoutData });
      setTimeout(() => action?.payload.push('/login'), 0)
    } else {
      yield put({ type: LOGOUT_FAILED, data: null });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({ type: LOGOUT_FAILED, data: err });
  }
}

export function* watchForgotPassword(action) {
  const loaderHandle = showToastLoader('Sending password reset link...')
  try {
    const forgotData = yield call(forgotPasswordSaga, action.payload);
    if (forgotData.success) {
      if (forgotData?.data?.message === 'Email sent') {
        showToastSuccess('If a SIRP account exists for that email address, we will email you instructions for resetting your password', loaderHandle)
        yield put({ type: FORGOT_PASSWORD_SUCCESSED, data: forgotData });
      } else {
        dismissToastLoader(loaderHandle)
        yield put({ type: FORGOT_PASSWORD_SUCCESSED, data: forgotData });
      }
    }
  } catch (err) {
    showToastError(err?.response?.data?.data.message || 'Email sending failed',
      loaderHandle)
    yield put({ type: FORGOT_PASSWORD_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchResetPassword(action) {
  const loaderHandle = showToastLoader('Resetting password...')
  try {
    const resetData = yield call(resetPasswordSaga, action.payload);
    if (resetData.success) {
      showToastSuccess('Password reset', loaderHandle)
      yield put({ type: RESET_PASSWORD_SUCCESSED, data: resetData });
    } else {
      yield put({ type: RESET_PASSWORD_FAILED, data: null });
    }
  } catch (err) {
    dismissToastLoader(loaderHandle)
    yield put({ type: RESET_PASSWORD_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchChangePassword(action) {
  const loaderHandle = showToastLoader('Changing password...')
  try {
    const resetData = yield call(changePasswordSaga, action.payload);
    if (resetData.success) {
      showToastSuccess(resetData?.data?.message, loaderHandle)
      yield put({ type: CHANGE_PASSWORD_SUCCESSED, data: resetData });
      action?.history?.push('/login');
    } else {
      showToastError(resetData?.data?.message, loaderHandle)
      yield put({ type: CHANGE_PASSWORD_FAILED, data: null });
    }
  } catch (err) {
    showToastError(err?.response?.data?.data?.message, loaderHandle)
    yield put({ type: CHANGE_PASSWORD_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchInvitedUser(action) {
  const loaderHandle = showToastLoader('Registering user...')
  try {
    const resetData = yield call(invitedUserSaga, action.payload);
    if (resetData.success) {
      showToastSuccess('User registered', loaderHandle)
      yield put({ type: INVITED_USER_SUCCESSED, data: resetData });
    } else {
      yield put({ type: INVITED_USER_FAILED, data: null });
    }
  } catch (err) {
    dismissToastLoader(loaderHandle)
    yield put({ type: INVITED_USER_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchgoogleLogin(action) {
  try {
    const gLogin = yield call(googleLoginSaga, action.payload);
    if (gLogin.success) {
      if (gLogin.data.token) {
        localStorage.setItem('AccessToken', gLogin.data.token);
        yield put({ type: GOOGLE_AUTH_LOGIN_SUCCESSED, data: gLogin });
      } else {
        yield put({ type: GOOGLE_AUTH_LOGIN_FAILED, data: gLogin });
      }
    } else {
      yield put({ type: GOOGLE_AUTH_LOGIN_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: GOOGLE_AUTH_LOGIN_FAILED, data: err?.response?.data?.data });
    alert(err?.response?.data?.data.message);
  }
}

export function* watchMasterLogin(action) {
  try {
    const mLogin = yield call(masterLoginSaga, action.payload);
    if (mLogin.success) {
      if (mLogin?.data?.poc_force_date_range) {
        const custom_date = mLogin?.data?.poc_force_date_range?.split("/");
        const startD = moment(custom_date[0]).format('YYYY-MM-DD');
        const endD = moment(custom_date[1]).format('YYYY-MM-DD');
        localStorage.setItem('start', startD);
        localStorage.setItem('end', endD);
      }
      if (mLogin?.data?.default_dashboard !== '') {
        localStorage.setItem('defaultDashboard', mLogin?.data?.default_dashboard);
      }
      if (mLogin?.data?.token) {
        localStorage.setItem('AccessToken', mLogin?.data?.token);

        setTimeout(() => action.history.push('/dashboard'), 0)
        yield put({ type: MASTER_AUTH_LOGIN_SUCCESSED, data: mLogin });
      } else {
        yield put({ type: MASTER_AUTH_LOGIN_FAILED, data: mLogin });
        setTimeout(() => action.history.push('/login'), 0)
      }
    } else {

      yield put({ type: MASTER_AUTH_LOGIN_FAILED, data: null });
      setTimeout(() => action.history.push('/login'), 0)
    }
  } catch (err) {
    yield put({ type: MASTER_AUTH_LOGIN_FAILED, data: err?.response?.data?.data });
    setTimeout(() => action.history.push('/login'), 0)
  }
}
export function* watchVerifyCode(action) {
  try {
    const forgotData = yield call(verifyCodeSaga, action.payload);
    if (forgotData.success) {
      yield put({ type: VERIFY_CODE_SUCCESSED, data: forgotData });
    } else {
      yield put({ type: VERIFY_CODE_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: VERIFY_CODE_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchUserConfirmation(action) {
  const loaderHandle = showToastLoader('Confirming User...')
  try {
    const response = yield call(userConfirmationSaga, action.payload);
    if (response.success) {
      showToastSuccess(response?.data[0], loaderHandle)
      yield put({ type: USER_CONFIRMATION_SUCCESSED, data: response });
    } else {
      yield put({ type: USER_CONFIRMATION_FAILED, data: null });
    }
  } catch (err) {
    showToastError(err?.response?.data?.data[0] || 'User confirmation failed');
    yield put({ type: USER_CONFIRMATION_FAILED, data: err?.response?.data?.data });
  } finally {
    dismissToastLoader(loaderHandle);
  }
}

export default function* watcher() {
  yield takeLatest(LOGIN_REQUESTED, watchLoginDetails);
  yield takeLatest(LOGOUT_REQUESTED, watchUserLogout);
  yield takeLatest(FORGOT_PASSWORD_REQUESTED, watchForgotPassword);
  yield takeLatest(RESET_PASSWORD_REQUESTED, watchResetPassword);
  yield takeLatest(CHANGE_PASSWORD_REQUESTED, watchChangePassword);
  yield takeLatest(INVITED_USER_REQUESTED, watchInvitedUser);
  yield takeLatest(GOOGLE_AUTH_LOGIN_REQUESTED, watchgoogleLogin);
  yield takeLatest(MASTER_AUTH_LOGIN_REQUESTED, watchMasterLogin);
  yield takeLatest(VERIFY_CODE_REQUESTED, watchVerifyCode);
  yield takeLatest(USER_CONFIRMATION_REQUESTED, watchUserConfirmation)
}
